<template>
  <div id="AssistTranslation" class="wiki">
    <div class="content">
      <section class="section" id="register-crowdin-account">
        <h1>如何註冊Crowdin的帳號?</h1>
        <p>
          首先請先連到
          <a target="_blank" href="https://crowdin.com/">crowdin(點我)</a> 網站
          <br />
          前往網站之後找到右上角的按鈕(如下圖)，選擇 「SING UP」(註冊)
        </p>
        <img
          src="@/assets/images/Wiki/AssistTranslation/logIn-logOut.png"
          alt="登入/註冊按鈕"
        />
        <p>
          點選後會出現類似下方的畫面，請照著上方的格式輸入
          Email(電子郵件)、Username(使用者名稱)、Password(密碼)，輸入完成後勾選
          「 I agree to the Terms and Conditions and Privacy Policy
          」(我同意條款和條件以及隱私權政策)，最後按下 「Create
          account」(建立帳號) 即可，如果是自己或家用電腦可以勾選 「Remember
          Me」(記住我)，就不用每次打開網頁都要登入一次。也可以與其它社群帳號連動，比較快登入。<br />
          通常註冊完帳號會叫你到電子郵件信箱裡收驗證信，完成步驟之後，就可以拿到Crowdin的帳號可以登入囉。
        </p>
        <img
          src="@/assets/images/Wiki/AssistTranslation/create-account.png"
          alt="註冊畫面"
        />
      </section>
    </div>
  </div>
</template>

<script>
/* 協助翻譯 */
/* eslint-disable no-unused-vars */
export default {
  name: "AssistTranslation",
  data() {
    return {};
  },
};
</script>