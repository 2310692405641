<template>
  <div id="Cosmic-Chat"></div>
</template>

<script>
export default {
  name: "Cosmic-Chat",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>