<template>
  <div id="AssistTranslation" class="wiki">
    <div class="content">
      <section class="section" id="future-goals">
        <h1>未來的發展目標?</h1>
        <p>
          RPMTW的旨在，「讓 Minecraft 中文社群更將強壯」，未來將會推出越來越多的產品，詳細內容可以查看
          RPMTW官方Discord群組的<a
            target="_blank"
            href="https://discord.com/channels/815819580840607807/815861320153956363"
          >
            ┗🔶┃建議提供 </a
          >這個頻道，也歡迎大家提供建議。
        </p>
      </section>
    </div>
  </div>
</template>

<script>
/* 其他問題 */
/* eslint-disable no-unused-vars */
export default {
  name: "AssistTranslation",
  data() {
    return {};
  },
  components: {},
};
</script>

<style lang="scss" scoped>
</style>