<template>
  <div id="InstallAndUse" class="wiki">
    <div class="content">
      <section class="section" id="how-install">
        <h1>如何安裝 RPMTW Platform Mod?</h1>
        <router-link to="/install/version"
          >&lt;&lt;點我查看教學&gt;&gt;</router-link
        >
      </section>
      <section class="section" id="if-game-crashes">
        <h1>遊戲因為裝了這個模組崩潰了怎麼辦?</h1>
        <p>
          請至 RPMTW 官方 Discord 群組的<a
            target="_blank"
            href="https://discord.com/channels/815819580840607807/852389500733751337"
          >
            ┣❓┃我要問問題 </a
          >這個頻道尋求協助。。
        </p>
      </section>
    </div>
  </div>
</template>

<script>
/* 安裝及使用 */
/* eslint-disable no-unused-vars */
export default {
  name: "InstallAndUse",
  data() {
    return {};
  },
  components: {},
};
</script>
